import { store } from "@/store/store";
import { proyecto } from "@/shared/dtos/proyecto";
import {
  VuexModule,
  Mutation,
  getModule,
  Module,
  Action,
} from "vuex-module-decorators";
import { ssmHttpService } from "@/shared/services/http-service";
import { API } from "@/shared/api";
import { proyecto_fichaDto } from "@/shared/dtos/proyecto_fichaDto";
import { document_fileDto } from "@/shared/dtos/document_fileDto";
import { UsuarioAllDto } from "@/shared/dtos/usuariosAllDto";
import { Usuario } from "@/shared/dtos/usuario";

@Module({
  namespaced: true,
  name: "proyectoModule",
  store,
  dynamic: true,
})
class proyectoModule extends VuexModule {
  public proyectos: proyecto[] = [];
  public proyecto: proyecto = new proyecto();
  public proyecto_ficha: proyecto_fichaDto = new proyecto_fichaDto();
  public proyectos_files: document_fileDto[] = [];
  public usuarios: UsuarioAllDto[] = [];

  @Action({ commit: "onGetproyectos" })
  public async getproyectos() {
    return await ssmHttpService.get(API.proyecto);
  }

  @Action({ commit: "onGetproyectos" })
  public async getproyectosActivos() {
    return await ssmHttpService.get(API.proyecto + "/activos");
  }

  @Action({ commit: "onGetproyectos" })
  public async getproyectosInActivos() {
    return await ssmHttpService.get(API.proyecto + "/inactivos");
  }

  @Action({ commit: "onGetusuarios" })
  public async getusuariosTareaProyecto(id: number) {
    return await ssmHttpService.get(API.proyecto + "/usuarios_tarea/" + id);
  }

  @Action({ commit: "onGetusuarios" })
  public async getusuariosProyecto(id: number) {
    return await ssmHttpService.get(API.proyecto + "/usuarios_proyecto/" + id);
  }

  @Action({ commit: "onGetproyectos" })
  public async getproyectos_sin_cargando(activo: boolean) {
    if (activo) {
      return await ssmHttpService.get(API.proyecto + "/activos");
    } else {
      return await ssmHttpService.get(API.proyecto + "/inactivos");
    }
  }

  @Action({ commit: "onGetproyecto" })
  public async getproyecto(id: any) {
    return await ssmHttpService.get(API.proyecto + "/" + id);
  }

  @Action({ commit: "onGetproyectoficha" })
  public async getproyectoficha(id: any) {
    return await ssmHttpService.get(API.proyecto + "/ficha/" + id);
  }

  @Action({ commit: "onGetproyectofiles" })
  public async getfilesproyecto(id: any) {
    return await ssmHttpService.get(API.proyecto + "/documents/" + id);
  }

  @Action
  public async guardarproyecto(proyecto: proyecto) {
    /*toJson() no existe pero existe en la extends de BaseDto*/
    return await ssmHttpService.post(API.proyecto, proyecto.toJson());
  }
  @Action
  public async guardar_ficha_proyecto(proyecto: proyecto_fichaDto) {
    /*toJson() no existe pero existe en la extends de BaseDto*/
    return await ssmHttpService.post(
      API.proyecto + "/ficha/",
      proyecto.toJson()
    );
  }
  @Action
  public async modificarproyecto(proyecto: proyecto) {
    return await ssmHttpService.put(API.proyecto + "/" + proyecto.id, proyecto);
  }
  @Action
  public async modificar_ficha_proyecto(proyecto: proyecto_fichaDto) {
    return await ssmHttpService.put(API.proyecto + "/ficha/", proyecto);
  }
  @Action
  public async modificarproyecto_sin_cargando(proyecto: proyecto) {
    return await ssmHttpService.put(
      API.proyecto + "/" + proyecto.id,
      proyecto,
      false
    );
  }

  @Action
  public async eliminarproyecto(proyecto: proyecto) {
    return await ssmHttpService.delete(
      API.proyecto + "/" + proyecto.id,
      null,
      false
    );
  }

  @Action({ commit: "onGetproyectofiles" })
  public async eliminar_documento_proyecto({ proyecto, doc }: any) {
    return await ssmHttpService.put(
      API.proyecto + "/documents/" + proyecto.id,
      doc,
      false
    );
  }

  @Mutation
  public onGetproyectos(res: proyecto[]) {
    this.proyectos = res ? res.map((x) => new proyecto(x)) : [];
  }

  @Mutation
  public onGetproyectofiles(res: document_fileDto[]) {
    this.proyectos_files = res ? res.map((x) => new document_fileDto(x)) : [];
  }

  @Mutation
  public onGetproyecto(res: proyecto) {
    this.proyecto = new proyecto(res);
  }

  @Mutation
  public onGetproyectoficha(res: proyecto_fichaDto) {
    this.proyecto_ficha = new proyecto_fichaDto(res);
  }

  @Mutation
  public onGetusuarios(res: Usuario[]) {
    this.usuarios = res ? res.map((x) => new UsuarioAllDto(x)) : [];
  }
}
export default getModule(proyectoModule);
