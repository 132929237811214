import { incidenciaAllDto } from "./incidencias/incidenciaAllDto";
import { proyecto } from "./proyecto";
import { tareaAllDto } from "./tareas/tareaAllDto";
import { Usuario } from "./usuario";

export class proyecto_fichaDto extends proyecto {
  public Usuarios!: Usuario[];
  public incidencias!:incidenciaAllDto[];
  public tareas!:tareaAllDto[];
}
