import { BaseDto } from "@/shared/dtos/base-dto";
import { departamento } from "./departamento";
import { enumeracion } from "./enumeracion";

export class proyecto extends BaseDto {
  public nombre!: string;
  public descripcion!: string;
  public fecha_inicio_prevista!: Date;
  public fecha_fin_prevista!: Date;
  public fecha_inicio_real!: Date;
  public id_departamento!: number;
  public id_proyecto_padre!: number;
  public id_estado!: number;
  public id_prioridad!: number;
  public orden!: number;
  public prioridad!:enumeracion;
  public departamento!:departamento;
}
